const NavMenu = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.0218 0.443359H0V22.4434H22.0218V0.443359Z"
        fill="white"
        fillOpacity="0.01"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.00195 4.80446C2.00195 4.36219 2.36048 4.00366 2.80275 4.00366H19.219C19.6613 4.00366 20.0198 4.36219 20.0198 4.80446C20.0198 5.24672 19.6613 5.60525 19.219 5.60525H2.80275C2.36048 5.60525 2.00195 5.24672 2.00195 4.80446ZM2.00199 11.8115C2.00199 11.3693 2.36051 11.0107 2.80278 11.0107H19.2191C19.6613 11.0107 20.0199 11.3693 20.0199 11.8115C20.0199 12.2538 19.6613 12.6123 19.2191 12.6123H2.80278C2.36051 12.6123 2.00199 12.2538 2.00199 11.8115ZM2.80278 18.0177C2.36051 18.0177 2.00199 18.3762 2.00199 18.8185C2.00199 19.2607 2.36051 19.6193 2.80278 19.6193H19.2191C19.6613 19.6193 20.0199 19.2607 20.0199 18.8185C20.0199 18.3762 19.6613 18.0177 19.2191 18.0177H2.80278Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default NavMenu
